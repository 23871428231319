@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;700;900&family=Playfair+Display:ital,wght@0,500;1,800;1,900&display=swap');
.bar .logo {
    max-height: 2.857143em;
    max-width: none;
}
.btn:disabled,.btn--primary:disabled{
    background: #9f9673!important;
    border: #9f9673!important;
}
.bar.visible-xs{
    background: #343435;
}

.logo-text{
    font-size: 2em;
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    padding-left: 8px;
    font-family: 'Playfair Display', serif;
    color: #dbd6d3!important;
}
.bg--dark{
    background: #161616;
}
a:hover {
    text-decoration: none;
}
.border--round {
    border-radius: 20px;
}
.bg--black{
    background:black;
}
.btn-mint .btn__text{
    font-size: 1.6em!important;
}
h1{
    font-weight: 800;
    font-size: 4.6em;
    font-family: 'Playfair Display', serif;
    font-style: italic;
}
h2{
    font-weight: 800;
    font-size: 4em;
    font-family: 'Playfair Display', serif;
}
@media (max-width: 767px){
    h3, .h3 {
        font-size: 1.8em;
        line-height: 1.2em;
    }
}
[class*='bg-']:not(.bg--white):not(.bg--secondary) p, [class*='imagebg']:not(.image--light) p {
    opacity: 0.6;
    
}
p{
    font-family: 'Inter', sans-serif;
    font-size: 1.4em;
}
.font-display{
    font-family: 'Playfair Display', serif;
}
h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'inter', sans-serif;
    font-weight: 700;
    color:#bda129!important;
}
.btn{
    font-family: 'Inter', sans-serif;
}
.top-bg h1{
    font-size: 4.6em;
}
.text-gold{
    color: #bda129!important;
}
h3.timer{
    font-size: 3em;;
    font-weight: 900;
}

img.number_dot{
    height: 50px;
    background: white;
    border-radius: 5px;
    padding: 2px 4px;
    margin-right: 8px;
}
/* button */
.btn .btn__text, .btn i {
    font-weight: 700;
    font-size: 1.16em;
}
.pt-0{
    padding-top: 0!important;
}

/* primary button */
.btn--primary, .btn--primary:visited {
    background: #bda129;
    border-color: #bda129;
}
.btn--primary:hover, .btn--primary:active {
    background: #efcb32!important;
}
.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(149 149 149 / 25%);
}
.bg--dark .btn .btn__text {
    color: #161616;
}
.btn-calendar{
    font-size: 13px;
    margin-left: 5px;
    border: 1px solid;
        padding: 4px;
        border-radius: 5px;
}

.top-bg{
    background-image:url('./img/bg_3.png');
    background-size: auto 100%;
    background-position: right;
    background-repeat: repeat-x;
}

.main-section{
    background-image:url('./img/bg-one.png');
    background-size: 150px 150px;
    background-repeat: repeat;
}
@media (max-width: 767px){
    .sec-carousel{
        margin-top: 6rem;
        margin-bottom: 1rem;
    }
    h2{
        margin-top: 8rem;;
    }
    .top-bg h1 {
        font-size: 3.6em;
    }
    h3.timer {
        font-size: 2.2em;
        font-weight: 900;
        line-height: 0.5;
    }
    .logo-text {
        font-size: 1.4em;
    }
}


